import { useToast } from "@chakra-ui/react";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useSaveVaultProfile } from "@equidefi/portals/hooks/useVault";
import { isValidPhoneNumber } from "react-phone-number-input";
import {useNavigate} from "react-router";
import * as Yup from "yup";

import { PhoneField } from "@equidefi/portals/components/Auth";
import { Input } from "@equidefi/ui/forms";
import { MESSAGE } from "../../../../../issuer/src/constants/forms";
import useWorkflowPosition from "../../../hooks/useWorkflowPosition";
import { WorkflowContainer } from "../WorkflowContainer";
import WorkflowForm from "../WorkflowForm";
import { useApi } from "@equidefi/portals/hooks/useApi";
import VaultClient from "@equidefi/portals/clients/VaultClient";
import {useWorkflowContext} from "@/pages/workflow/context/WorkflowContext";

const schema = Yup.object().shape({
  first_name: Yup.string()
    .trim()
    .nullable()
    .min(2, "First name must be at least 2 characters")
    .required(MESSAGE.REQUIRED("First name")),
  middle_name: Yup.string().trim().nullable(),
  last_name: Yup.string()
    .trim()
    .nullable()
    .min(2, "Last name must be at least 2 characters")
    .required(MESSAGE.REQUIRED("Last name")),
  phone_number: Yup.string()
    .nullable()
    .required()
    .test({
      name: "phone_number",
      message: "Must be a valid phone number",
      test: (value) => (!!value ? isValidPhoneNumber(value) : false),
    }),
});

const DEFAULT_DATA = {
  first_name: null,
  middle_name: null,
  last_name: null,
  phone_number: null,
};

export function WorkflowProfile() {
  const { investment, offering, refetchInvestment } = useWorkflowContext();
  const navigate = useNavigate();
  useWorkflowPosition(1);

  const saveVaultProfile = useSaveVaultProfile(investment?.id);
  const toast = useToast();
  const [spinner, showSpinner] = useState(false);

  const onSubmit = async (data) => {
    showSpinner(true);

    try {
      await saveVaultProfile.mutateAsync({
        first_name: data.first_name?.trim(),
        middle_name: data.middle_name?.trim() || null,
        last_name: data.last_name?.trim(),
        phone_number: data.phone_number,
      });

      refetchInvestment();

      navigate(`/offerings/${offering.slug}/${investment?.id}/subscription`);
    } catch (error) {
      console.error(error);
      toast({
        status: "error",
        description: "There was an error trying to save your profile data",
      });
      showSpinner(false);
    }
  };

  const {
    handleSubmit,
    setFieldValue,
    handleBlur,
    getFieldProps,
    errors,
    touched,
    isValid,
  } = useFormik({
    initialValues: DEFAULT_DATA,
    enableReinitialize: true,
    validationSchema: schema,
    onSubmit,
    validateOnChange: true,
    validateOnBlur: true,
    validateOnMount: true,
  });

  return (
    <WorkflowContainer
      title="Investor Information"
      onContinue={handleSubmit}
      buttonProps={{
        isDisabled: !isValid,
        mt: 2,
        isLoading: spinner,
      }}
    >
      <WorkflowForm onSubmit={handleSubmit}>
        <Input
          label="First Name"
          placeholder="e.g. John"
          isRequired
          error={touched.first_name && errors.first_name}
          isInvalid={errors.first_name && touched.first_name}
          {...getFieldProps("first_name")}
        />
        <Input
          label="Middle Name"
          placeholder="e.g. David"
          error={touched.middle_name && errors.middle_name}
          isInvalid={errors.middle_name && touched.middle_name}
          {...getFieldProps("middle_name")}
        />
        <Input
          label="Last Name"
          placeholder="e.g. Smith"
          isRequired
          error={touched.last_name && errors.last_name}
          isInvalid={errors.last_name && touched.last_name}
          {...getFieldProps("last_name")}
        />
        <PhoneField
          handleBlur={handleBlur}
          error={touched?.phone_number && errors?.phone_number}
          setFieldValue={setFieldValue}
          {...getFieldProps("phone_number")}
        />
      </WorkflowForm>
    </WorkflowContainer>
  );
}
