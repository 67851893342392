import { tabsAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(tabsAnatomy.keys);

// define a custom variant
const equidefiVariant = definePartsStyle((props) => {
  return {
    tab: {
      _selected: {
        borderColor: 'purple.400 !important',
        borderBottom: '3px solid',
      },
    },
    tablist: {
      borderBottom: 'none',
    }
  }
})

const variants = {
  equidefi: equidefiVariant,
}

// export the component theme
export const tabsTheme = defineMultiStyleConfig({ variants })

