import { Input } from "@equidefi/ui";

export const AddressForm = ({ formik }) => {
  const { errors, touched, getFieldProps } = formik;
  if (!formik.values.address_street_1) return null;

  return (
    <>
      <Input
        label="Street Address"
        isInvalid={touched.address_street_1 && !!errors.address_street_1}
        isRequired
        error={errors.address_street_1}
        {...getFieldProps("address_street_1")}
      />
      <Input
        label="Street Address 2 (Unit #)"
        isInvalid={touched.address_street_2 && !!errors.address_street_2}
        error={errors.address_street_2}
        {...getFieldProps("address_street_2")}
      />
      <Input
        label="City"
        isInvalid={touched.address_city && !!errors.address_city}
        error={errors.address_city}
        isRequired
        {...getFieldProps("address_city")}
      />
      <Input
        label="State"
        isInvalid={touched.address_state && !!errors.address_state}
        error={errors.address_state}
        isRequired
        {...getFieldProps("address_state")}
      />
      <Input
        label="Postal Code"
        isInvalid={touched.address_postal_code && !!errors.address_postal_code}
        error={errors.address_postal_code}
        isRequired
        {...getFieldProps("address_postal_code")}
      />
      <Input
        label="Country"
        isInvalid={touched.address_country_code && !!errors.address_country_code}
        error={errors.address_country_code}
        isRequired
        {...getFieldProps("address_country_code")}
      />
    </>
  );
};
