import { Box, BoxProps, Link as CLink, Flex, forwardRef, useMultiStyleConfig } from "@chakra-ui/react";
import { ReactNode } from "react";
import { Link } from "react-router";
import { Icon } from "../../icon";
import { V2Theme } from "../../theme";

export interface InvestmentSummaryBoxProps extends BoxProps {
  icon: ReactNode;
  status: "unknown" | "ready" | "pending" | "error";
  href: string;
  children: ReactNode;
  onClick?: () => void;
}

export const InvestmentSummaryBox = forwardRef<
  InvestmentSummaryBoxProps,
  "div"
>((props, ref) => {
  const { icon, status, href, children, onClick, ...rest } = props;
  const styles = useMultiStyleConfig("InvestmentSummaryBox", {
    variant: status,
    theme: V2Theme,
  });

  const content = (
    <Box __css={styles.container} width="100%" {...rest}>
      <Box __css={styles.iconWrapper}>{icon}</Box>

      <Flex
        ref={ref}
        onClick={(e) => {
          if (onClick) {
            e.preventDefault();
            onClick();
          }
        }}
        __css={styles.whiteCard}
        flex={1}
        width="100%"
      >
        <Flex __css={styles.contentWrapper} flex={1} width="100%">
          {children}
        </Flex>

        <Flex __css={styles.arrowWrapper}>
          <Flex>
            <Icon.ArrowRightCircle size={20} />
          </Flex>
        </Flex>
      </Flex>
    </Box>
  );

  if (href) {
    return (
      <CLink
        w="full"
        as={Link}
        to={href}
        _hover={{ textDecoration: 'none' }}
      >
        {content}
      </CLink>
    );
  }

  return content;
});

export interface InvestmentSummaryBoxColProps {
  title: string;
  text?: ReactNode;
  bold?: boolean;
}

export const InvestmentSummaryBoxCol = forwardRef<
  InvestmentSummaryBoxColProps,
  "div"
>((props, ref) => {
  const { title, text, bold, ...rest } = props;
  const styles = useMultiStyleConfig("InvestmentSummaryBox");

  return (
    <Flex
      ref={ref}
      __css={styles.column}
      flex={1}
      minWidth="0"
      {...rest}
    >
      <Box __css={styles.columnTitle}>{title}</Box>
      <Box
        __css={styles.columnText}
        fontWeight={typeof text === "string" && bold ? "semibold" : "normal"}
      >
        {text}
      </Box>
    </Flex>
  );
});

InvestmentSummaryBox.displayName = "InvestmentSummaryBox";
InvestmentSummaryBoxCol.displayName = "InvestmentSummaryBoxCol";
