import { VStack } from "@chakra-ui/react";
import { useEffect } from "react";
import { useNavigate } from "react-router";

import { useDocumentTitle } from "@equidefi/portals/hooks/useDocumentTitle";
import InvestmentCard from "../../../components/investment/InvestmentCard";
import { investmentContinueUrl } from "@/helpers/investment";
import {useCanReinvest, useUserInvestments} from "@/hooks/useInvestments";
import { WorkflowContainer } from "../WorkflowContainer";
import {useWorkflowContext} from "@/pages/workflow/context/WorkflowContext";

const WorkflowChooseInvestment = () => {
  const navigate = useNavigate();
  const { offering } = useWorkflowContext();
  const { data: investments, isFetchedAfterMount } = useUserInvestments(
    {
      offering_id: offering?.id,
    },
    { refetchOnMount: true }
  );
  const canReinvest = useCanReinvest(investments);

  useDocumentTitle("Choose an Investment");

  useEffect(() => {
    if (isFetchedAfterMount && investments?.length === 0) {
      navigate(`/offerings/${offering.slug}/registration`);
    }
  }, [isFetchedAfterMount, investments, offering.slug, navigate]);

  return (
    <WorkflowContainer
      title="Choose An Investment"
      buttonProps={{
        isDisabled: !canReinvest,
      }}
      onContinue={() => navigate(`/offerings/${offering.slug}/reinvest`)}
      buttonLabel="Reinvest"
    >
      <VStack gap={4} mb={4} mt={4}>
        {investments?.map((investment) => (
          <InvestmentCard
            key={investment.id}
            investment={investment}
            cardAction={() => {
              const url = investmentContinueUrl(
                investment.id,
                investment.next,
                offering.slug,
                investment.status
              );

              navigate(url ?? `/vault/investment/${investment.id}`);
            }}
          />
        ))}
      </VStack>
    </WorkflowContainer>
  );
};

export default WorkflowChooseInvestment;
