import axios, { AxiosInstance } from "axios";

import { createContext, useContext } from "react";

export type ApiClientClass<T> = new (...args: unknown[]) => T;

export const ApiClientContext = createContext<AxiosInstance>(axios);

export function useApi<T>(Client: ApiClientClass<T>) {
  const axiosInstance = useContext(ApiClientContext);

  return new Client(axiosInstance);
}

export function useApiInstance() {
  return useContext(ApiClientContext);
}
