import { WorkflowContainer } from "@/pages/workflow/WorkflowContainer";
import { Badge, Box, Flex, HStack, VStack } from "@chakra-ui/react";
import React from "react";
import { useGetUserVaults } from "@equidefi/portals/hooks/useVault";
import { Heading, Text } from "@equidefi/ui";
import { Icon } from "@equidefi/ui/icon";
import { mergeName } from "@/helpers/string";
import { useCreateInvestment } from "@equidefi/portals/hooks/useInvestments";
import { useCookies } from "react-cookie";
import moment from "moment";
import { useNavigate } from "react-router";
import { WorkflowButton } from "@/components/workflow/WorkflowButton";
import { useWorkflowContext } from "@/pages/workflow/context/WorkflowContext";
import {BannerTypeMap} from "@/constants/investment";


const VaultRecord = ({vault, onClick}) => {
  if (!vault) return null;

  return (
    <Box
      w="full"
      h="auto"
      borderRadius="8px"
      bg="linear-gradient(150deg, black 0%, #3e3e3e 100%)"
      p="4"
      color="white"
      onClick={() => {
        if (vault.status === "APPROVED") {
          onClick(vault)
        }
      }}
      cursor={vault.status === "APPROVED" ? "pointer" : null}
    >
      <Badge
        h="6"
        lineHeight="6"
        fontSize="14px"
        justifyContent="center"
        bgColor={BannerTypeMap[vault?.status] || "equidefi.yellow"}
        w="full"
        textAlign="center"
      >
        {vault?.status}
      </Badge>

      <VStack gap="2.5" minW="full" mt="3">
        <Flex minW="full" justifyContent="space-between" alignItems="flex-start">
            <VStack m={0} p={0} alignItems="flex-start">
              <Heading mb="0" textStyle="h2" fontWeight="bold">
                {mergeName(vault)}
              </Heading>
              <HStack alignItems="flex-start">
                  <Icon.User size={20} color="white" />
                  <Text m={0}>Individual</Text>
              </HStack>
              <Text>{vault.phone_number}</Text>
            </VStack>
            <VStack alignItems="flex-end">
              <Text p="0" m="0">{vault.address_street_1} {vault.address_street_2}</Text>
              <Text m="0">{vault.address_city} {vault.address_state} {vault.address_postal_code}</Text>
              <Text m="0">{vault.address_country_code}</Text>
            </VStack>
        </Flex>
        <VStack>

        </VStack>
        <VStack>
          <Text>Created on: {moment(vault?.create_date).format("MM/DD/YYYY")}</Text>
        </VStack>
      </VStack>
    </Box>
  )
}

export default function WorkflowReinvest() {
  const navigate = useNavigate();
  const { offering, user } = useWorkflowContext();
  const { data: vaults, isLoading } = useGetUserVaults(user.id);
  const createInvestment = useCreateInvestment();
  const [cookies] = useCookies(["bcode"]);

  const onSelectAccreditation = async (vault) => {
    const response = await createInvestment.mutateAsync({
      offering_id: offering.id,
      vault_id: vault.id,
      bcode: cookies.bcode
    })
    navigate(`/offerings/${offering.slug}/${response.id}/subscription`);
  }

  return (
    <WorkflowContainer
      title="Select one of your vault records to reinvest"
      buttonProps={{
        isLoading: isLoading,
      }}
    >
      <Text>Below are your vault records, if you would like to use the same information simply click on the vault record. If ANY information needs to be changed for your next investment, please click start a new one.</Text>
      <VStack gap={4} mb={4} mt={4}>
        {vaults?.records?.map((vault) => (
          <VaultRecord
            key={vault.id}
            vault={vault}
            onClick={onSelectAccreditation}
          />
        ))}
        <Text mt={5} mb={0}>Need make some changes?</Text>
        <WorkflowButton>Create a new vault record</WorkflowButton>
      </VStack>
    </WorkflowContainer>
  );
}

