import { Box, Flex, Stack } from "@chakra-ui/react";
import { Heading, Text } from "@equidefi/ui";
import NavBar from "../components/nav/NavBar";

export default function NotFound() {
  return (
    <Stack
      as="main"
      bgColor="background"
      direction={{ base: "column", lg: "row" }}
    >
      <NavBar>
        <Flex
          direction="column"
          align="center"
          justify={{ base: "start", lg: "center" }}
          w="full"
          minH="100vh"
        >
          <Box maxWidth="560px" w="full" p={4} as="form">
            <Heading fontWeight="bold" textStyle="h1">
              Page Not Found
            </Heading>
            <Text>Sorry, this page does not exist</Text>
          </Box>
        </Flex>
      </NavBar>
    </Stack>
  );
}
