import { useEffect } from "react";
import { useNavigate } from "react-router";
import {useWorkflowContext} from "@/pages/workflow/context/WorkflowContext";

export default function WorkflowRedirect() {
  const { offering, investment } = useWorkflowContext();

  const navigate = useNavigate();

  useEffect(() => {
    if (offering && investment) {
      navigate(
        `/offerings/${offering.slug}/${
          investment?.id
        }/${investment?.next.toLowerCase()}`,
        {
          replace: true,
        }
      );
    }
  }, [navigate, offering, investment]);

  return <></>;
}
