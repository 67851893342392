import { Box } from "@chakra-ui/react";
import Markdown from "react-markdown";

const ALLOWED_ELEMENTS = [
  "p",
  "span",
  "ul",
  "ol",
  "li",
  "strong",
  "em",
  "blockquote",
  "br",
  "a",
];

const components = {
  blockquote(props) {
    const { children, className } = props;

    return (
      <Box
        as="blockquote"
        pl={3}
        color="gray.500"
        borderLeft="2px solid"
        borderColor="gray.200"
        className={className}
      >
        {children}
      </Box>
    );
  },
};

export function EquidefiMarkdown({ children }) {
  return (
    <Markdown allowedElements={ALLOWED_ELEMENTS} components={components}>
      {children}
    </Markdown>
  );
}
