import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";

import { useToast } from "@chakra-ui/react";
import { useAuth } from "@equidefi/portals/hooks/useAuth";
import { useCurrentUser } from "@equidefi/portals/hooks/useUsers";

import { investmentContinueUrl } from "../helpers/investment";
import { useInvestmentJumpToStep } from "../hooks/use-investment-jump-to-step";
import { useInvestment } from "../hooks/useInvestments";
import { useOffering } from "../hooks/useOfferings";
import Spinner from "./../components/spinner";

const Auth = () => {
  const navigate = useNavigate();
  const { investmentId, token } = useParams();
  const { data: user } = useCurrentUser();
  const toast = useToast();
  const { setToken } = useAuth();

  const [enableLoad, setEnableLoad] = useState(() => false);
  const { data: investment } = useInvestment(investmentId, {
    onError: () => {
      toast({ status: "error", description: "Sorry, the link has expired." });
      navigate(`/investor/link-expired`);
    },
  });

  const { data: offering } = useOffering(investment?.offering_id, {
    onError: () => {
      toast({ status: "error", description: "Sorry, the link has expired." });
      navigate(`/investor/link-expired`);
    },
  });

  const redirectOffering = useCallback(() => {
    const url = investmentContinueUrl(
      investment?.id,
      investment?.next,
      offering?.slug,
      investment?.status
    );
    if (!url) {
      if (investment?.status !== "PENDING") {
        toast({
          status: "info",
          description: "Your investment was already completed",
        });
      } else {
        toast({
          status: "warning",
          description: "Something went wrong. Please login to your vault.",
        });
      }
    }
    navigate(url ?? "/vault/dashboard", { replace: true });
  }, [investment?.next, investment?.id, offering?.slug, investment?.status]);

  const [, setInvestmentId] = useInvestmentJumpToStep(offering?.id);

  useEffect(() => {
    const dataCall = async () => {
      setToken(token);
      setEnableLoad(true);
    };
    dataCall();
  }, [user]);

  useEffect(() => {
    if (enableLoad && investment && offering) {
      setInvestmentId(investment.id);
      redirectOffering();
    }
  }, [enableLoad, investment, offering]);

  return <Spinner show={true} />;
};

export default Auth;
