import {
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  FormLabelProps,
  Box,
} from "@chakra-ui/react";
import { FC, forwardRef } from "react";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";

export interface PhoneFieldProps {
  name: string;
  value?: string;
  onChange?: (value: string) => void;
  helperText?: string;
  label: string;
  error?: string;
  labelProps?: FormLabelProps;
  isDisabled?: boolean;
  isRequired?: boolean;
  isInvalid?: boolean;
  placeholder?: string;
  defaultCountry?: string;
}

export const PhoneField: FC<PhoneFieldProps> = forwardRef(
  (
    {
      name,
      value,
      onChange,
      label,
      helperText,
      isDisabled = false,
      isRequired = false,
      isInvalid = false,
      error,
      labelProps = {},
      placeholder = "Enter phone number",
      defaultCountry = "US",
      ...rest
    },
    ref
  ) => {
    return (
      <FormControl
        isDisabled={isDisabled}
        isRequired={isRequired}
        isInvalid={isInvalid}
        mt="1"
      >
        <FormLabel mt="0" p="0" fontSize="md" {...labelProps}>
          {label}
        </FormLabel>
        <Box
          className="PhoneInput"
          h="40px"
          display="block"
          sx={{
            '.PhoneInputInput': {
              h: "40px",
              w: "full",
              bg: "white",
              border: "1px solid",
              borderColor: "inherit",
              borderRadius: "5px",
              p: "10px 12px",
              _focus: {
                borderColor: "purple.500",
                boxShadow: "0 0 0 1px var(--chakra-colors-purple-500)",
                zIndex: 1
              }
            },
            '.PhoneInputCountry': {
              marginRight: "8px"
            }
          }}
        >
          <PhoneInput
            ref={ref as any}
            name={name}
            value={value}
            onChange={onChange as any}
            defaultCountry={defaultCountry as any }
            placeholder={placeholder}
            international
            {...rest}
          />
        </Box>
        <FormErrorMessage>{error}</FormErrorMessage>
        <FormHelperText>{helperText}</FormHelperText>
      </FormControl>
    );
  }
);

// Add display name for React DevTools
PhoneField.displayName = "PhoneField";

// Export validation helper
export { isValidPhoneNumber };
