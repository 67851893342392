import { useState, useEffect, useCallback } from 'react';

const SEND_MFA_CODE_COUNTDOWN_SECONDS = 30;

interface UseCountdownProps {
  initialSeconds?: number;
  autoStart?: boolean;
}

interface UseCountdownReturn {
  countdown: number;
  isActive: boolean;
  startCountdown: () => void;
  resetCountdown: () => void;
}

export const useCountdown = ({
  initialSeconds = SEND_MFA_CODE_COUNTDOWN_SECONDS,
  autoStart = false,
}: UseCountdownProps = {}): UseCountdownReturn => {
  const [countdown, setCountdown] = useState(0);
  const [endTime, setEndTime] = useState<number | null>(null);

  const startCountdown = useCallback(() => {
    setEndTime(Date.now() + initialSeconds * 1000);
  }, [initialSeconds]);

  const resetCountdown = useCallback(() => {
    setEndTime(null);
    setCountdown(0);
  }, []);

  useEffect(() => {
    if (autoStart) {
      startCountdown();
    }
  }, [autoStart, startCountdown]);

  useEffect(() => {
    if (!endTime) return;

    const interval = setInterval(() => {
      const now = Date.now();
      const distance = endTime - now;
      const seconds = Math.ceil(distance / 1000);

      if (distance <= 0) {
        clearInterval(interval);
        resetCountdown();
      } else {
        setCountdown(seconds);
      }
    }, 100);

    return () => clearInterval(interval);
  }, [endTime, resetCountdown]);

  return {
    countdown,
    isActive: countdown > 0,
    startCountdown,
    resetCountdown,
  };
};
