import React, { useEffect } from "react";
import { useNavigate } from "react-router";
import Spinner from "../../../components/spinner";
import { useEventDispatcher } from "@/hooks/useInvestments";
import { WorkflowContainer } from "../WorkflowContainer";
import {useWorkflowContext} from "@/pages/workflow/context/WorkflowContext";

const WorkflowPaymentProcessing = () => {
  const { investment, offering, user, refetchInvestment } = useWorkflowContext();
  const navigate = useNavigate();
  const dispatchEvent = useEventDispatcher();

  useEffect(() => {
    (async () => {
      await dispatchEvent.mutateAsync({
        event: "PAYMENT",
        action: "SUBMITTED",
        investmentId: investment.id,
        actor: { user: user.id },
        meta: {
          type: "ach",
        },
      });
      await refetchInvestment();

      if (investment.expedited) {
        navigate(`/offerings/${offering.slug}/${investment?.id}/agreement`);
      } else {
        navigate(`/offerings/${offering.slug}/${investment?.id}/address`);
      }
    })();
  }, []);

  return (
    <WorkflowContainer title="Payment Complete">
      <Spinner show />
    </WorkflowContainer>
  );
};

export default WorkflowPaymentProcessing;
