import React, {createContext, FC, PropsWithChildren, useMemo} from "react";
import {useGetVault} from "../hooks/useVault";


type VaultContextType = {
  vaultId?: string | null;
  vault: object | null;
  refetch: () => void;
};

type InvestmentProps = {
  vault_id: string | null;
}

type VaultProviderProps = {
  investment: InvestmentProps;
}

export const VaultContext = createContext<VaultContextType>({
  vaultId: null,
  vault: null,
  refetch: () => {}
});


const VaultProvider: FC<PropsWithChildren<VaultProviderProps>> = ({ children, investment }) => {
  const vaultId = useMemo<string | null>(
    () => investment?.vault_id,
    [investment?.vault_id]
  );
  const {data: vault, refetch} = useGetVault(vaultId);

  return (
    <VaultContext.Provider
      value={{
        vaultId,
        vault,
        refetch,
      }}
    >
      {children}
    </VaultContext.Provider>
  );
}

export default VaultProvider;
