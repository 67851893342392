import { gsap } from "gsap";
import React, { useLayoutEffect, useRef } from "react";

import Logo from "../../images/logo.png";
import { QUOTES } from "./constants";
import styles from "./index.module.css";

const LoadingView = ({ isError }) => {
  const loadingBar = useRef();
  const loader = useRef();
  const selectedIndex = Math.round(Math.random() * 5);
  useLayoutEffect(() => {
    let ctx = gsap.context(() => {
      gsap.from(loadingBar.current, {
        width: 0,
        duration: 1.85,
        ease: "circ.out",
        delay: 0.5,
      });
    }, loader);

    return () => ctx.revert();
  }, []);

  if (isError) {
    return (
      <div className={styles.loading}>
        <div className={styles.logo}>
          <img src={Logo} alt="EquiDeFi" />
        </div>
        <div className={styles.quoteText}>
          Failed to load the offering, please check your internet connection
        </div>
      </div>
    );
  }

  return (
    <div className={styles.loading} ref={loader}>
      <div className={styles.logo}>
        <img src={Logo} alt="EquiDeFi" />
      </div>
      <div className={styles.loadingBorder}>
        <div className={styles.loadingBar} ref={loadingBar} />
      </div>
      <div className={styles.quoteText}>
        <div className={styles.quote}>{QUOTES[selectedIndex].quote}</div>
        <div className={styles.line} />
        <div className={styles.person}>{QUOTES[selectedIndex].name}</div>
      </div>
    </div>
  );
};

export default LoadingView;
