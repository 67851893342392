import moment from "moment";

export const PAYMENT_CATEGORY_MAP = {
  0: "STRIPE",
  1: "WIRE",
  2: "CHECK",
};

export const PAYMENT_FAILED_STATUSES = ["canceled", "expired"];
export const PAYMENTS_CHANGE_METHOD_STATUSES = [
  ...PAYMENT_FAILED_STATUSES,
  "created",
  "pending",
];
export const PAYMENT_STRIKETHROUGH_AMOUNT_STATUSES = [
  ...PAYMENT_FAILED_STATUSES,
  "refunded",
];

export const PAYMENT_STATUS_DISPLAY = {
  created: {
    title: "Payment Pending",
    description: (payment) =>
      `You indicated you would pay by ${payment.payment_type}. Your payment will be pending until the funds are received by the issuer.`,
    color: "orange",
    status: "warning",
  },
  processing: {
    title: "Payment Processing",
    description: () => "Your payment is processing!",
    color: "orange",

    status: "warning",
  },
  payment_failed: {
    title: "Payment Failed",
    description: () => "Your payment has failed",
    color: "red",
    status: "error",
  },
  requires_payment_method: {
    color: "orange",
    title: "Missing Payment Method",
    description: () => "Your payment did not include a payment method",
    status: "warning",
  },
  requires_action: {
    color: "orange",
    title: "Requires Action",
    description: () => "Your payment requires action",
    status: "warning",
  },
  succeeded: {
    title: "Payment Successful",
    description: () => "Your payment was received successfully!",
    color: "green",
    status: "success",
  },
  refunded: {
    title: "Payment Refunded",
    description: () => "Your payment has been refunded by the issuer.",
    color: "red",
    status: "error",
  },
  canceled: {
    title: (payment) =>
      payment?.category === "MANUAL"
        ? "Payment Cancelled"
        : "Authorization Cancelled",
    description: (payment) =>
      payment?.category === "MANUAL"
        ? "Your payment has been canceled."
        : "The authorization placed on your card has cancelled.",
    color: "orange",
    status: "warning",
  },
  requires_capture: {
    title: "Payment Authorized",
    description: (payment) =>
      `Your card will be charged once your investment is approved. Authorization expires in ${moment(
        payment?.expiration_date
      ).diff(payment?.create_date, "days")} days.`,
    color: "blue",
    status: "info",
  },
  expired: {
    title: "Authorization Expired",
    description: () =>
      "The authorization placed on your card has expired. Please retry your payment.",
    color: "orange",
    status: "warning",
  },
};
