import { ApiClient } from "./ApiClient";


class VaultClient extends ApiClient {

  async getVault(vaultId: string) {
    if (!vaultId) return null;

    const response = await this.get(`/v2/vault/${vaultId}`);
    return response.data;
  }

  async getUserVaults(userId: string) {
    const response = await this.get(`/v2/vault/user/${userId}`);
    return response.data;
  }

  async createVault(investmentId: string) {
    const response = await this.post(`/v2/vault/create/${investmentId}`);
    return response.data;
  }

  async saveProfile(investmentId: string, data: object) {
    const response = await this.post(`/v2/vault/profile/${investmentId}`, data);
    return response.data;
  }

  async saveAddress(investmentId: string, data: object) {
    const response = await this.post(`/v2/vault/address/${investmentId}`, data);
    return response.data;
  }

  async saveEntity(investmentId: string, data: object) {
    const response = await this.post(`/v2/vault/entity/${investmentId}`, data);
    return response.data;
  }

  async updateVault(vaultId: string, payload: Object) {
    const response = await this.patch(
      `/v2/vault/${vaultId}`,
      payload
    );

    return response.data;
  }

  async updateVaultByInvestmentId(investmentId: string, payload: Object) {
    const response = await this.patch(
      `/v2/vault/investment/${investmentId}`,
      payload
    );

    return response.data;
  }

}

export default VaultClient;
