import axios from "axios";
import "bootstrap-daterangepicker/daterangepicker.css";
import { useFlags } from "launchdarkly-react-client-sdk";
import { Outlet } from "react-router";
import { RouterProvider } from "react-router/dom";

import MaintenanceScreen from "@equidefi/portals/components/MaintenanceScreen";
import { PrivateRoute, PublicRoute } from "@equidefi/portals/components/Routes";
import { useAuth } from "@equidefi/portals/hooks/useAuth";
import
  {
    useCurrentUser,
    useIdentifyLDUser,
  } from "@equidefi/portals/hooks/useUsers";

import { sentryCreateBrowserRouter } from "@/helpers/sentry";
import { useIntercomMessenger } from "./hooks/useIntercomMessenger";
import InvestmentOverview from "./pages/app/vault/InvestmentOverview";
import VaultLayout from "./pages/app/vault/layout";
import MyProfile from "./pages/app/vault/profile";
import Vault from "./pages/app/vault/vault";
import VaultPayment from "./pages/app/vault/VaultPayment";
import Auth from "./pages/auth";
import ErrorPage from "./pages/ErrorPage";
import Forgot from "./pages/login/forgot";
import Login from "./pages/login/login";
import MFA from "./pages/login/mfa";
import ResetPassword from "./pages/login/reset";
import NotFound from "./pages/NotFound";
import SupportPageLayout from "./pages/SupportPage";
import SupportPageForm from "./pages/SupportPageForm";
import SupportPageSuccess from "./pages/SupportPageSuccess";
import WorkflowRoot from "./pages/workflow";
import { WorkflowAccreditation } from "./pages/workflow/accreditation";
import { AdditionalSigner } from "./pages/workflow/AdditionalSigner";
import { WorkflowAddress } from "./pages/workflow/address";
import { WorkflowAgreements } from "./pages/workflow/agreement";
import WorkflowCompleted from "./pages/workflow/complete";
import WorkflowRootError from "./pages/workflow/error";
import { WorkflowKYC } from "./pages/workflow/kyc";
import { WorkflowPayment } from "./pages/workflow/payment";
import { WorkflowPaymentManual } from "./pages/workflow/payment/PaymentManual";
import WorkflowPaymentProcessing from "./pages/workflow/payment/PaymentProcessing";
import { WorkflowProfile } from "./pages/workflow/profile";
import WorkflowRegistration from "./pages/workflow/registration";
import WorkflowChooseInvestment from "./pages/workflow/registration/ChooseInvestment";
import WorkflowMFA from "./pages/workflow/registration/MFA";
import { WorkflowSigners } from "./pages/workflow/signers";
import { WorkflowSubscription } from "./pages/workflow/subscription";
import WorkflowRedirect from "./pages/workflow/WorkflowRedirect";
import WorkflowReinvest from "@/pages/workflow/registration/WorkflowReinvest";

const App = (props) => {
  const { data: me } = useCurrentUser();
  useIdentifyLDUser(me);
  useIntercomMessenger();
  const flags = useFlags();
  const { token, isLoggedIn } = useAuth();

  // @TODO remove after deprecating axios.defaults calls
  if (isLoggedIn) {
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  }

  if (flags.maintenanceMode) return <MaintenanceScreen />;

  const router = sentryCreateBrowserRouter(
    [
      {
        path: "/",
        element: (
          <PublicRoute fallbackUrl="/vault/dashboard">
            <Login />
          </PublicRoute>
        ),
        errorElement: <ErrorPage />,
      },
      {
        path: "/mfa",
        element: (
          <PublicRoute fallbackUrl="/vault/dashboard">
            <MFA />
          </PublicRoute>
        ),
        errorElement: <ErrorPage />,
      },
      {
        path: "/forgot",
        element: (
          <PublicRoute fallbackUrl="/vault/dashboard">
            <Forgot />
          </PublicRoute>
        ),
        errorElement: <ErrorPage />,
      },
      {
        path: "/reset/:userId/:token",
        element: (
          <PublicRoute fallbackUrl="/vault/dashboard">
            <ResetPassword />
          </PublicRoute>
        ),
        errorElement: <ErrorPage />,
      },

      {
        path: "/auth/:redirectUrl/:investmentId/:token",
        element: <Auth />,
        errorElement: <ErrorPage />,
      },

      {
        path: "/support",
        element: <SupportPageLayout />,
        errorElement: <ErrorPage />,
        children: [
          {
            path: "",
            element: <SupportPageForm />,
            errorElement: <ErrorPage />,
          },
          {
            path: "success",
            element: <SupportPageSuccess />,
            errorElement: <ErrorPage />,
          },
        ],
      },

      {
        path: "/vault",
        element: (
          <PrivateRoute fallbackUrl="/">
            <VaultLayout />
          </PrivateRoute>
        ),
        errorElement: <ErrorPage />,
        children: [
          {
            index: true,
            path: "dashboard",
            element: <Vault />,
            errorElement: <ErrorPage />,
          },
          {
            path: "investment/:id",
            element: <InvestmentOverview />,
            errorElement: <ErrorPage />,
          },
          {
            path: "investment/:id/payment",
            element: <VaultPayment />,
          },
          {
            path: "profile",
            element: <MyProfile />,
            errorElement: <ErrorPage />,
          },
        ],
      },

      {
        path: "/offerings/:slug",
        element: <WorkflowRoot />,
        errorElement: <WorkflowRootError />,
        children: [
          {
            path: "registration",
            element: <WorkflowRegistration />,
          },
          {
            path: "mfa",
            element: (
              <PublicRoute fallbackUrl="../choose">
                <WorkflowMFA />
              </PublicRoute>
            ),
          },
          {
            path: "choose",
            element: (
              <PrivateRoute fallbackUrl="../registration">
                <WorkflowChooseInvestment />
              </PrivateRoute>
            ),
          },
          {
            path: "reinvest",
            element: <WorkflowReinvest />,
          },
          {
            path: ":investment_id",
            element: (
              <PrivateRoute fallbackUrl="/">
                <Outlet />
              </PrivateRoute>
            ),
            children: [
              {
                path: "profile",
                element: <WorkflowProfile />,
              },
              {
                path: "address",
                element: <WorkflowAddress />,
              },
              {
                path: "signers",
                element: <WorkflowSigners />,
              },
              {
                path: "kyc",
                element: <WorkflowKYC />,
              },
              {
                path: "subscription",
                element: <WorkflowSubscription />,
              },
              {
                path: "payment",
                element: <WorkflowPayment />,
              },
              {
                path: "payment/manual",
                element: <WorkflowPaymentManual />,
              },
              {
                path: "payment/processing",
                element: <WorkflowPaymentProcessing />,
              },
              {
                path: "agreement",
                element: <WorkflowAgreements />,
              },
              {
                path: "accreditation",
                element: <WorkflowAccreditation />,
              },
              {
                path: "complete",
                element: <WorkflowCompleted />,
              },
              {
                path: "*",
                element: <WorkflowRedirect />,
              },
            ],
          },
        ],
      },
      {
        path: "/investment/signer/:investmentAgreementId/:signatureId",
        element: <AdditionalSigner />,
        errorElement: <ErrorPage />,
      },
      {
        path: "/offerings/:offeringId/*",
        element: <WorkflowRedirect />,
        errorElement: <ErrorPage />,
      },
      {
        path: "*",
        element: <NotFound />,
        errorElement: <ErrorPage />,
      },
    ],
    {
      v7_relativeSplatPath: false,
    }
  );

  return <RouterProvider router={router} />;
};

export default App;
