import
  {
    Accordion,
    AccordionButton,
    AccordionIcon,
    AccordionItem,
    AccordionPanel,
    Alert,
    AlertDescription,
    AlertIcon,
    Box,
    Tooltip,
  } from "@chakra-ui/react";
import { Elements } from "@stripe/react-stripe-js";
import React, { useContext, useMemo } from "react";

import { EquidefiMarkdown } from "@equidefi/portals/components/Markdown";
import { MAXIMUM_STRIPE_AMOUNT } from "@equidefi/shared/constants/payments";
import { Text } from "@equidefi/ui";

import StripePaymentForm from "../../../components/StripePaymentForm";
import { WorkflowButton } from "../../../components/workflow/WorkflowButton";
import { CurrencyFormatter } from "../../../constants/format";
import { APPEARANCE } from "../../../constants/stripe";
import { PaymentStepContext } from "./context";

const AccordionSection = React.forwardRef(
  ({ title, children, alertText, panelProps = {}, ...rest }, ref) => {
    return (
      <AccordionItem {...rest} ref={ref} my="2">
        {({ isExpanded }) => {
          return (
            <Box
              borderRadius="lg"
              p={2}
              border={isExpanded ? "1px solid" : null}
            >
              <AccordionButton>
                <input
                  type="radio"
                  style={{ marginRight: 5 }}
                  checked={isExpanded}
                />
                <Text fontWeight="bold" m="0">
                  {title}
                </Text>
                <AccordionIcon ml="auto" />
              </AccordionButton>
              <AccordionPanel {...panelProps}>
                {alertText && (
                  <Alert
                    status="warning"
                    borderRadius="lg"
                    border="1px solid"
                    borderColor="equidefi.yellow"
                    boxShadow="sm"
                    mb={4}
                  >
                    <AlertIcon />
                    <Text textStyle="body2" m={0}>
                      {alertText}
                    </Text>
                  </Alert>
                )}
                {children}
              </AccordionPanel>
            </Box>
          );
        }}
      </AccordionItem>
    );
  }
);

export const PaymentAccordion = ({
  onSubmitManualPayment = () => {},
  isManualPaymentLoading = false,
  supportsStripePayment,
  onSectionChange,
  paymentSettings,
  stripe,
  onReady = () => {},
  investment,
  offering,
  redirectUrl = `/offerings/${offering?.slug}/${investment?.id}/payment/processing`,
}) => {
  const { clientSecret, paymentIntentId } = useContext(PaymentStepContext);

  const options = useMemo(() => {
    return {
      clientSecret,
      options: {
        theme: "stripe",
        appearance: APPEARANCE,
      },
    };
  }, [clientSecret]);

  return (
    <Accordion variant="card" allowToggle onChange={onSectionChange}>
      <Tooltip
        label={
          !supportsStripePayment
            ? `Your payment is over our current limit of ${CurrencyFormatter.format(
                MAXIMUM_STRIPE_AMOUNT
              )} for Credit Card or ACH.`
            : null
        }
        hasArrow
        openDelay={250}
      >
        <AccordionSection
          title="Credit card / ACH"
          isDisabled={!supportsStripePayment}
          panelProps={{ minHeight: "200px" }}
        >
          <Text textStyle="body2" m="0">
            <Alert
              status="warning"
              borderRadius="lg"
              border="1px solid"
              borderColor="equidefi.yellow"
              boxShadow="sm"
              mb={4}
            >
              <AlertIcon alignSelf="flex-start" />
              <AlertDescription>
                <Text textStyle="body2" fontWeight="bold">
                  Please read carefully
                </Text>
                <Text textStyle="body2">
                  When paying by credit card a temporary hold will be placed on
                  your account until you can establish accredited investor
                  status. Once established, your credit card will be
                  automatically charged. If you are unable to establish
                  accredited investor status within 5 business days the hold on
                  your funds will be released and you will have to re-enter your
                  credit card information to continue with your investment.
                </Text>
              </AlertDescription>
            </Alert>

            {clientSecret && (
              <Elements stripe={stripe} options={options}>
                <StripePaymentForm
                  investmentId={investment?.id}
                  paymentIntentId={paymentIntentId}
                  returnPath={redirectUrl}
                  offering={offering}
                />
              </Elements>
            )}
          </Text>
        </AccordionSection>
      </Tooltip>

      <AccordionSection
        title="Wire transfer"
        alertText="It may take 1 business day to complete the transaction."
      >
        <Text textStyle="body2">
          Please initiate your wire to the account listed below:
        </Text>
        <Text textStyle="body2" m={0}>
          {paymentSettings.wireInstructions.length > 0 && (
            <EquidefiMarkdown>
              {paymentSettings.wireInstructions}
            </EquidefiMarkdown>
          )}
        </Text>
        <WorkflowButton
          isLoading={isManualPaymentLoading}
          onClick={() => onSubmitManualPayment()}
        >
          Continue
        </WorkflowButton>
      </AccordionSection>

      <AccordionSection
        title="Check"
        alertText="It may take several days to complete the transaction."
      >
        <Text textStyle="body2">
          Include the investment amount and send your check to:
        </Text>
        <Text textStyle="body2" m={0}>
          {paymentSettings.checkInstructions.length > 0 && (
            <EquidefiMarkdown>
              {paymentSettings.checkInstructions}
            </EquidefiMarkdown>
          )}
        </Text>
        <WorkflowButton
          isLoading={isManualPaymentLoading}
          onClick={() => onSubmitManualPayment()}
        >
          Continue
        </WorkflowButton>
      </AccordionSection>
    </Accordion>
  );
};
