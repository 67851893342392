import { Alert, AlertIcon } from "@chakra-ui/react";
import { useNavigate } from "react-router";

import { Text } from "@equidefi/ui";
import { WorkflowContainer } from "./WorkflowContainer";
import {useWorkflowContext} from "@/pages/workflow/context/WorkflowContext";

const WorkflowStepError = ({ title, text, onResume }) => {
  const { offering, investment } = useWorkflowContext();
  const navigate = useNavigate();

  const resumeInvestment = () => {
    if (onResume) {
      onResume();
      return;
    }

    navigate(
      `/offerings/${offering.slug}/${
        investment?.id
      }/${investment.next.toLowerCase()}`
    );
  };

  return (
    <WorkflowContainer onContinue={resumeInvestment} title={title}>
      <Alert
        status="error"
        borderRadius="lg"
        border="1px solid"
        borderColor="equidefi.red"
        boxShadow="sm"
        my={4}
      >
        <AlertIcon />
        <Text textStyle="body2" m={0}>
          {text}
        </Text>
      </Alert>
    </WorkflowContainer>
  );
};

export default WorkflowStepError;
