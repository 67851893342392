import { HStack, IconButton, Tooltip, forwardRef, useMultiStyleConfig } from "@chakra-ui/react";
import { Icon } from "@equidefi/ui/icon";
import { V2Theme } from "../../theme";

export type StatusType = "unknown" | "pending" | "success" | "error";

export interface InvestmentProgressChipsProps {
  size?: "sm" | "md" | "lg";
  paymentStatus?: StatusType;
  kycStatus?: StatusType;
  agreementStatus?: StatusType;
  accreditationStatus?: StatusType;
  accreditation?: boolean;
}

export const InvestmentProgressChips = forwardRef<InvestmentProgressChipsProps, "div">(
  (props, ref) => {
    const {
      size = "sm",
      paymentStatus = "unknown",
      kycStatus = "unknown",
      agreementStatus = "unknown",
      accreditationStatus = "unknown",
      accreditation = true,
    } = props;

    // Get styles for each status variant
    const paymentStyles = useMultiStyleConfig("InvestmentProgressChips", { size, variant: paymentStatus, theme: V2Theme });
    const kycStyles = useMultiStyleConfig("InvestmentProgressChips", { size, variant: kycStatus, theme: V2Theme });
    const agreementStyles = useMultiStyleConfig("InvestmentProgressChips", { size, variant: agreementStatus, theme: V2Theme });
    const accreditationStyles = useMultiStyleConfig("InvestmentProgressChips", { size, variant: accreditationStatus, theme: V2Theme });

    return (
      <HStack ref={ref} spacing={2} verticalAlign="middle">
        <Tooltip label="Payment" hasArrow placement="top">
          <IconButton
            icon={<Icon.DollarSign size="1em" />}
            aria-label="Payment Status Icon"
            size={size}
            __css={paymentStyles.chip}
          />
        </Tooltip>
        
        <Tooltip label="KYC / KYB" hasArrow placement="top">
          <IconButton
            icon={<Icon.Search size="1em" />}
            aria-label="KYC/KYB Status Icon"
            size={size}
            __css={kycStyles.chip}
          />
        </Tooltip>
        
        <Tooltip label="Agreement" hasArrow placement="top">
          <IconButton
            icon={<Icon.Edit3 size="1em" />}
            aria-label="Agreement Status Icon"
            size={size}
            __css={agreementStyles.chip}
          />
        </Tooltip>
        
        {accreditation && (
          <Tooltip label="Accreditation" hasArrow placement="top">
            <IconButton
              icon={<Icon.File size="1em" />}
              aria-label="Accreditation Status Icon"
              size={size}
              __css={accreditationStyles.chip}
            />
          </Tooltip>
        )}
      </HStack>
    );
  }
);

InvestmentProgressChips.displayName = "InvestmentProgressChips"; 