export const REINVEST_STATUSES = ["APPROVED", "COMPLETED", "CLOSED"];

export const BannerTypeMap = {
  PENDING: "equidefi.yellow",
  SUBMITTED: "equidefi.grren",
  APPROVED: "equidefi.green",
  REJECTED: "equidefi.red",
  COMPLETED: "equidefi.green",
  REVIEW: "equidefi.green",
  TERMINATED: "equidefi.red",
  CLOSED: "equidefi.green",
};

export const BannerTextMap = {
  PENDING: "INCOMPLETE",
  SUBMITTED: "SUBMITTED",
  APPROVED: "PROCESSING",
  REJECTED: "REJECTED",
  COMPLETED: "PROCESSING",
  REVIEW: "PROCESSING",
  TERMINATED: "TERMINATED",
  CLOSED: "COMPLETE",
};
