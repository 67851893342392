import { useAuth } from "@equidefi/portals/hooks/useAuth";
import { offeringIsActive } from "@equidefi/shared";

import OfferingError from "../../../components/offerings/OfferingError";
import useWorkflowPosition from "../../../hooks/useWorkflowPosition";
import { useWorkflowContext } from "@/pages/workflow/context/WorkflowContext";

import ExistInvestorRegister from "./ExistInvestorRegister";
import Register from "./Register";

const WorkflowRegistration = (props) => {
  const { offering } = useWorkflowContext();
  const { isLoggedIn } = useAuth();

  useWorkflowPosition(1);

  if (offering?.status && !offeringIsActive(offering)) {
    return (
      <OfferingError
        title="Offering Not Available"
        message={`Sorry but you cannot invest in this offering, yet.`}
      />
    );
  }

  return isLoggedIn ? (
    <ExistInvestorRegister offering={offering} />
  ) : (
    <Register offering={offering} />
  );
};

export default WorkflowRegistration;
