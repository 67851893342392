import { Text as ChakraText, TextProps } from "@chakra-ui/react";

import { Breakpoints } from "./Global";
import { EquidefiTheme } from "./theme";

type TextStyles = Extract<keyof typeof EquidefiTheme.textStyles, string>;

interface TypographyProps extends Omit<TextProps, "textStyle"> {
  textStyle?: TextStyles | { [key in Breakpoints]: TextStyles };
}

export const Text = ({
  textStyle = "body",
  as = "p",
  ...rest
}: TypographyProps) => <ChakraText as={as} textStyle={textStyle} {...rest} />;

export const Heading = ({
  textStyle = "h2",
  as = "h2",
  ...rest
}: TypographyProps) => <ChakraText as={as} textStyle={textStyle} {...rest} />;
