import NumberFormat from "react-number-format";
import moment from "moment/moment";
import React from "react";
import { useWorkflowContext } from "@/pages/workflow/context/WorkflowContext";

export default function ConvertibleForm({
  values,
  handleBlur,
  setValues,
  errors,
  touched,
}) {
  const { offering } = useWorkflowContext();

  const totalAmount = Number(offering?.remaining_amount);
  const unitPrice = Number(offering?.unit_price?.replace(/[^0-9.-]+/g, ""));

  return (
    <>
      <div className="row">
        <div className="col-md-4">
          <div className="card">
            <div className="card-body p-4">
              <h6 className="text-uppercase mb-1 text-muted">Total Amount</h6>
              <h3 className="mb-0">
                <NumberFormat
                  displayType="text"
                  thousandSeparator
                  fixedDecimalScale={2}
                  decimalScale={2}
                  value={totalAmount}
                  prefix="$"
                />
              </h3>
            </div>
          </div>
        </div>
        {offering?.additional_info?.enter_maturity_date ? (
          <div className="col-md-4">
            <div className="card">
              <div className="card-body p-4">
                <h6 className="text-uppercase mb-1 text-muted">
                  Maturity Date
                </h6>
                <h3 className="mb-0">
                  {moment(offering?.additional_info?.maturity_date).format(
                    "LL"
                  )}
                </h3>
              </div>
            </div>
          </div>
        ) : (
          <div className="col-md-4">
            <div className="card">
              <div className="card-body p-4">
                <h6 className="text-uppercase mb-1 text-muted">
                  Term (in months)
                </h6>
                <h3 className="mb-0">{offering?.additional_info?.term}</h3>
              </div>
            </div>
          </div>
        )}
        <div className="col-md-4">
          <div className="card">
            <div className="card-body p-4">
              <h6 className="text-uppercase mb-1 text-muted">
                Conversion Price
              </h6>
              <h3 className="mb-0">
                {offering?.unit_price === "$0.00"
                  ? `As per Note`
                  : `${offering?.unit_price}`}
              </h3>
            </div>
          </div>
        </div>
      </div>

      <div className="row mt-4">
        <div className="col-md-4">
          <div className="card">
            <div className="card-body p-4">
              <h6 className="text-uppercase mb-1 text-muted">
                Original Issuance Discount ($)
              </h6>
              <h3 className="mb-0">${offering?.additional_info?.oic_amount}</h3>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="card">
            <div className="card-body p-4">
              <h6 className="text-uppercase mb-1 text-muted">
                Original Issuance Discount (%)
              </h6>
              <h3 className="mb-0">
                {offering?.additional_info?.oic_percentage}%
              </h3>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="card">
            <div className="card-body p-4">
              <h6 className="text-uppercase mb-1 text-muted">
                Original Principal Amount
              </h6>
              <h3 className="mb-0">
                {offering?.additional_info?.original_principle_amount && (
                  <NumberFormat
                    prefix="$"
                    decimalScale={0}
                    value={offering?.additional_info?.original_principle_amount}
                    displayType="text"
                    thousandSeparator
                  />
                )}
              </h3>
            </div>
          </div>
        </div>
      </div>

      <div className="row mt-4">
        <div className="col-12 col-md-6">
          <div className="form-group">
            <label>Investment amount</label>
            <NumberFormat
              name="amount"
              className="form-control form-control-lg"
              prefix="$"
              allowNegative={false}
              thousandSeparator={true}
              value={values.amount}
              isAllowed={({ formattedValue, floatValue }) => {
                return formattedValue === "" || floatValue <= totalAmount;
              }}
              onBlur={handleBlur}
              onValueChange={({ value }) => {
                setValues((current) => ({
                  ...current,
                  amount: value,
                  quantity: unitPrice > 0 ? value / unitPrice : 0,
                }));
              }}
              fixedDecimalScale={0}
              decimalScale={0}
            />
            {errors.amount && (touched.quantity || touched.amount) && (
              <div className="text-danger">{errors.amount}</div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
