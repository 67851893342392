import { anatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const parts = anatomy("investmentProgressChips").parts("chip");

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(parts.keys);

const baseStyle = definePartsStyle({
  chip: {
    borderRadius: "0.3rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    bg: "secondary",
    color: "white",
    border: "none",
    fontWeight: "bold",
  },
});

const sizes = {
  sm: definePartsStyle({
    chip: {
      minW: "32px",
      h: "32px",
      fontSize: "sm",
    },
  }),
  md: definePartsStyle({
    chip: {
      minW: "40px",
      h: "40px",
      fontSize: "md",
    },
  }),
  lg: definePartsStyle({
    chip: {
      minW: "48px",
      h: "48px",
      fontSize: "lg",
    },
  }),
};

const variants = {
  unknown: definePartsStyle({
    chip: {
      bg: "gray",
    },
  }),
  pending: definePartsStyle({
    chip: {
      bg: "equidefi.yellow",
    },
  }),
  success: definePartsStyle({
    chip: {
      bg: "equidefi.green",
    },
  }),
  error: definePartsStyle({
    chip: {
      bg: "equidefi.red",
    },
  }),
};

export const investmentProgressChipsTheme = defineMultiStyleConfig({
  baseStyle,
  sizes,
  variants,
  defaultProps: {
    size: "sm",
    variant: "unknown",
  },
}); 