import * as Sentry from "@sentry/react";
import React from "react";
import { useRouteError } from "react-router";

import GenericInfoArea from "@/components/GenericInfoArea";

export default function ErrorPage() {
  const error = useRouteError();

  React.useEffect(() => {
    Sentry.captureException(error);
  }, [error]);

  return (
    <GenericInfoArea
      heading=":("
      title="Oops!"
      message={
        <>
          Sorry, an unexpected error has occurred. Please contact{" "}
          <a href="/support">
            <u>support</u>
          </a>{" "}
          if you require assistance.
        </>
      }
    />
  );
}
