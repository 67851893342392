import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import InvestmentClient from "../clients/InvestmentClient";
import { useApi } from "./useApi";

/**
 * onMutateInvestment will update the cache within react-query for investment,
 * this will prevent the need to constantly call refetchInvestment on components
 * when values are already available in memory from an update.
 *
 * @param client
 * @param updatePayload
 * @param investmentId
 */
export const onMutateInvestment = async (client: any, updatePayload: object, investmentId: string) => {
  await client.cancelQueries(['investment', investmentId]);
  const previousInvestment: object | undefined = client.getQueryData(['investment', investmentId]);
  const newInvestment = {
    ...previousInvestment,
    ...updatePayload
  }

  client.setQueryData(['investment', investmentId], newInvestment);

  return {previousInvestment, newInvestment};
}

/**
 *
 * @param client
 * @param investmentId
 * @param error
 * @param newData - The new investment record data after it has been updated
 * @param context
 */
export const onErrorInvestment = async (client: any, investmentId: string, error: any, newData: object, context: any) => {
  client.setQueryData(
    ['investment', investmentId],
    context.previousInvestment
  )
}


export const useCreateInvestment = (options = {}) => {
  const investmentApi = useApi(InvestmentClient);
  return useMutation(
    (data: { offering_id: string; bcode?: string, vault_id?: string }) =>
      investmentApi.create(data.offering_id, data.bcode, data.vault_id),
    options
  );
};

export const useGetInvestment = (investmentId, options = {}) => {
  const investmentApi = useApi(InvestmentClient);

  return useQuery(
    ["investment-vault", investmentId],
    () => investmentApi.getInvestmentById(investmentId),
    {
      ...options,
    }
  );
};

export const useSubmitSubscription = (investmentId: string, options = {}) => {
  const client = useQueryClient();
  const investmentApi = useApi(InvestmentClient);

  return useMutation(
    (data: Record<string, any>) => investmentApi.submitSubscription(investmentId, data),
    {
      onMutate: async (payload) => await onMutateInvestment(client, payload, investmentId),
      onError: async (err, newData, context) => await onErrorInvestment(client, investmentId, err, newData, context),
      ...options
    },
  );
}

export const useUpdateInvestment = (id: string, options = {}) => {
  const investmentApi = useApi(InvestmentClient);
  return useMutation((data: object) => investmentApi.update(id, data), options);
};

