import
  {
    Accordion,
    AccordionButton,
    AccordionIcon,
    AccordionItem,
    AccordionPanel,
    Box,
    Flex,
    Stack,
  } from "@chakra-ui/react";
import { useCallback, useEffect, useMemo, useState } from "react";
import NumberFormat from "react-number-format";
import { useParams } from "react-router";

import
  {
    useAgreementSigner,
    useEmbeddedSignature,
  } from "@equidefi/portals/hooks/useSignatures";
import { Heading, Paper, Text } from "@equidefi/ui";

import GenericInfoArea from "@/components/GenericInfoArea";
import InvestmentCard from "@/components/investment/InvestmentCard";
import NavBar from "@/components/nav/NavBar";
import Spinner from "@/components/spinner";
import { CONFIG } from "@/constants/config";

import { WorkflowContainer } from "./WorkflowContainer";

const UnsignedScreen = ({
  primarySigner,
  closed = false,
  investment,
  onSign = () => {},
  onClose = () => {},
}) => {
  const { investmentAgreementId, signatureId } = useParams();
  const { start, isLoading: isLoadingSignature } = useEmbeddedSignature({
    testMode: CONFIG.ENV !== "production",
    skipDomainVerification: CONFIG.ENV !== "production",
    onClose,
    onSign,
  });

  const handleSignature = useCallback(async () => {
    start(investmentAgreementId, signatureId);
  }, [investmentAgreementId, signatureId, start]);

  return (
    <WorkflowContainer
      buttonLabel="Continue to Sign"
      buttonProps={{
        isDisabled: closed,
        loadingText: "Please Wait",
        isLoading: isLoadingSignature,
      }}
      onContinue={() => handleSignature()}
      title="You've Been Invited to Sign"
      minHeight="100vh"
      mt={6}
    >
      <Text>
        You’ve been invited by {primarySigner?.full_name} as an additional signer in
        the following investment:
      </Text>
      <Box mb={4}>
        <InvestmentCard investment={investment} hideStatusBanner />
      </Box>
      <Accordion variant="card" allowToggle>
        <AccordionItem>
          <AccordionButton>
            <Box as="span" flex="1" textAlign="left">
              <Heading mb="0" textStyle="h3" fontWeight="700">
                Investment Details
              </Heading>
            </Box>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel>
            <Box as="ul" m={0} p={0}>
              <Flex as="li" justify="space-between" w="full">
                <Text>Investment Amount</Text>
                <NumberFormat
                  displayType="text"
                  name="amount"
                  prefix="$"
                  allowNegative={false}
                  thousandSeparator
                  value={investment?.amount}
                  fixedDecimalScale={0}
                  decimalScale={2}
                />
              </Flex>
              <Flex as="li" justify="space-between" w="full">
                <Text>Shares</Text>

                <NumberFormat
                  displayType="text"
                  name="quantity"
                  allowNegative={false}
                  thousandSeparator
                  value={investment?.quantity}
                  decimalScale={2}
                  fixedDecimalScale={0}
                />
              </Flex>
              <Flex as="li" justify="space-between" w="full">
                <Text>Share Price</Text>
                <NumberFormat
                  displayType="text"
                  name="quantity"
                  prefix="$"
                  allowNegative={false}
                  thousandSeparator
                  value={investment?.price}
                  decimalScale={2}
                  fixedDecimalScale={2}
                />
              </Flex>
              <Flex as="li" justify="space-between" w="full">
                <Text mb={0}>Primary Signer</Text>
                <Text mb={0}>{primarySigner?.full_name}</Text>
              </Flex>
            </Box>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
      <Text>
        As an additional signer, you only need to sign the investment
        agreements. You can do this from your smartphone, computer or tablet.
      </Text>
    </WorkflowContainer>
  );
};

const SignedScreen = ({ offeringAgreement }) => {
  return (
    <WorkflowContainer minHeight="100vh">
      <Flex align="center" justify="center" minHeight="75vh">
        <Box maxWidth="600px" width="100%">
          <Heading as="h1" fontWeight="bold" mb={2}>
            Signature Complete
          </Heading>
          <Text mb={4}>
            Congratulations! You have completed the signature process
            successfully.
          </Text>
          <Paper variant="primary" p={4} mb={6}>
            <Flex justify="space-between" align="center">
              <Box>
                <Heading as="h3" mb={0} textStyle="h2">
                  {offeringAgreement?.name}
                </Heading>
                <Text mb={0} fontSize="md" textStyle="context">
                  Signed
                </Text>
              </Box>
            </Flex>
          </Paper>
          <Text align="center">You can now safely close this page.</Text>
        </Box>
      </Flex>
    </WorkflowContainer>
  );
};

export const AdditionalSigner = () => {
  const { investmentAgreementId, signatureId } = useParams();
  const [closed, setClosed] = useState(false);
  const [signed, setSigned] = useState(false);

  const { data, refetch, isLoading, isError } = useAgreementSigner(
    investmentAgreementId,
    signatureId,
    {
      refetchInterval: closed ? 2_500 : false,
    }
  );

  const { investment_agreement, investment, offering_agreement } = data ?? {};

  const primarySigner = useMemo(
    () =>
      investment_agreement?.signing_parties.find(
        (party) => party.role === "signer1"
      ),
    [investment_agreement]
  );

  const signer = useMemo(
    () =>
      investment_agreement?.signing_parties.find(
        (party) => party.signature_id === signatureId
      ),
    [investment_agreement, signatureId]
  );

  const hasSigned = useMemo(
    () => signed || signer?.status === "signed",
    [signer?.status, signed]
  );

  const invalidAgreement = useMemo(() => {
    return (
      !investment_agreement ||
      Object.keys(investment_agreement).length === 0 ||
      isError
    );
  }, [investment_agreement, isError]);

  useEffect(() => {
    if (hasSigned) {
      setClosed(true);
    }
  }, [hasSigned]);

  if (!isLoading && invalidAgreement) {
    return (
      <GenericInfoArea
        heading=":("
        title="Invalid Signature Link"
        message="This signature link has expired or is no longer valid. Please contact the primary signer for an updated link."
      />
    );
  }

  const handleCloseSign = async () => {
    if (hasSigned) {
      setClosed(true);
      refetch();
    }
  };

  const handleSign = async () => {
    setSigned(true);
    handleCloseSign();
  };

  return (
    <Stack
      as="main"
      bgColor="background"
      direction={{ base: "column", lg: "row" }}
    >
      <NavBar offering={investment}>
        {isLoading ? (
          <Spinner show={isLoading} />
        ) : hasSigned ? (
          <SignedScreen offeringAgreement={offering_agreement} />
        ) : (
          <UnsignedScreen
            primarySigner={primarySigner}
            investment={investment}
            closed={closed}
            onClose={handleCloseSign}
            onSign={handleSign}
          />
        )}
      </NavBar>
    </Stack>
  );
};
