import * as Sentry from "@sentry/react";
import React from "react";
import
  {
    createBrowserRouter,
    createRoutesFromChildren,
    useLocation,
    useNavigationType,
  } from "react-router";

import { CONFIG } from "@/constants/config";

/**
 *
 * @param {string} appEnv
 * @returns string
 */
function sentryEnv(appEnv) {
  switch (appEnv) {
    case "prod":
      return "production";
    case "dev":
      return "development";
    default:
      return appEnv ?? "production";
  }
}

/**
 *
 * If there is no environment variable to configure the DSN endpoint for Sentry,
 * the Sentry integration should not be initialized

 * @returns {boolean}
 */
export function sentryIsEnabled() {
  return false;
  return process.env.NODE_ENV === "production" && !!CONFIG.SENTRY_DSN;
}

export function sentryStart() {
  Sentry.init({
    enabled: sentryIsEnabled(),
    environment: sentryEnv(CONFIG.ENV),
    dsn: CONFIG.SENTRY_DSN,
    integrations: [
      // See docs for support of different versions of variation of react router
      // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
      Sentry.reactRouterV7BrowserTracingIntegration({
        useEffect: React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
      }),
      Sentry.replayIntegration(),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}

export const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouterV7(createBrowserRouter);
