import { ApiClient } from "./ApiClient";

class InvestmentClient extends ApiClient {
  async dispatchEvent(investmentId: string, payload: Object) {
    const response = await this.post(
      `/v2/investments/${investmentId}/event`,
      payload
    );

    return response.data;
  }

  async create(offering_id: string, bcode?: string, vault_id?: string) {
    const response = await this.post(`/v2/investments/`, {
      offering_id,
      ...(bcode ? { bcode: `${bcode}`.trim() } : {}),
      vault_id,
    });

    return response.data;
  }

  async updateSigner(
    investmentId: string,
    signerId?: string,
    payload?: Object
  ) {
    const response = await this.patch(
      `/v2/agreements/signers/${investmentId}/${signerId}`,
      payload
    );

    return response.data;
  }

  async addSigner(investmentId: string, payload: Object) {
    const response = await this.put(
      `/v2/agreements/signers/${investmentId}`,
      payload
    );

    return response.data;
  }

  async deleteSigner(investmentId: string, signerId: string) {
    const response = await this.delete(
      `/v2/agreements/signers/${investmentId}/${signerId}`
    );

    return response.data;
  }

  async setupSigners(investmentId: string) {
    const response = await this.post(`/v2/agreements/signers/${investmentId}`);

    return response.data;
  }

  async get(id: string) {
    // Not sure where it's coming from but id is showing as a string with the value undefined
    if (!id || id === "undefined") {
      return;
    }
    const response = await super.get(`/v2/investments/${id}`);
    return response.data;
  }

  async update(id: string, data = {}) {
    const response = await this.patch(`/v2/investments/${id}`, data);
    return response.data;
  }

  async updateSubscription(id: string, data = {}) {
    const response = await this.patch(
      `/v2/investments/${id}/subscription`,
      data
    );
    return response.data;
  }

  async all(params: { offering_id?: string }) {
    const response = await super.get(`/v2/investments`, { params });
    return response.data;
  }

  async pokeInvestor(investmentId: string) {
    const response = await this.post(`/v2/investments/${investmentId}/poke`);

    return response.data;
  }

  async getInvestmentById(investmentId: string) {
    const response = await this.get(
      `/v2/investments/${investmentId}`
    );

    return response.data;
  }

  async submitSubscription(investmentId: string, payload: Object) {
    const response = await this.post(
      `/v2/investments/${investmentId}/subscription`,
      payload
    );

   return response.data;
  }

}

export default InvestmentClient;
