import {
  Tab as ChakraTab,
  TabProps as ChakraTabProps,
  Link as CLink,
  forwardRef,
  useStyleConfig
} from "@chakra-ui/react";
import { Link } from "react-router";
import { V2Theme } from "../../theme";

export interface TabProps extends Omit<ChakraTabProps, 'onClick'> {
  href?: string;
  onClick?: () => void;
  isSelected?: boolean;
}

export const Tab = forwardRef<TabProps, 'button'>((props, ref) => {
  const { href, onClick, children, isSelected, ...rest } = props;
  const styles = useStyleConfig("Tab", { theme: V2Theme });

  if (href) {
    return (
      <CLink
        as={Link}
        to={href}
        _hover={{ textDecoration: 'none' }}
      >
        <ChakraTab
          ref={ref}
          onClick={(e) => {
            if (onClick) {
              e.preventDefault();
              onClick();
            }
          }}
          aria-selected={isSelected}
          {...rest}
          sx={styles}
        >
          {children}
        </ChakraTab>
      </CLink>
    );
  }

  return (
    <ChakraTab
      ref={ref}
      onClick={onClick}
      aria-selected={isSelected}
      {...rest}
      sx={styles}
    >
      {children}
    </ChakraTab>
  );
});

Tab.displayName = 'Tab';
